import React, { Component } from "react"
import Layout from "../components/layout"
// import Helmet from "react-helmet"
import styled from "styled-components"

// import Website from "../components/Website"
// import AmazonItem from "../components/AmazonItem"

import Link from "../components/Link"
import { FaHome } from "react-icons/fa"

const Home = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  & a {
    background-color: #fff;
    box-shadow: 0 0 0 white;
  }
`

class Recommeds extends Component {
  render() {
    return (
      <Layout>
        <Home>
          <Link to="/">
            <FaHome />
          </Link>
        </Home>
        <div className="text-center">
          I recommend these things, in no particular order
        </div>
        <p>Coming Soon!</p>
      </Layout>
    )
  }
}

export default Recommeds
